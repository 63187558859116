import React, { FC } from "react";

import Content from "components/Content";
import Feature from "./components/Feature";
import styles from "./styles.module.scss";
import { features } from "./constants";

const Features: FC = () => {
  return (
    <Content contentClassName={styles.container}>
      <div className={styles.content}>
        {features.map((feature) => (
          <Feature {...feature} />
        ))}
      </div>
    </Content>
  );
};

export default Features;
