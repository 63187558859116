import Label from "components/Label";
import React, { FC } from "react";
import Image from "next/image";

import Content from "components/Content";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";

import text from "constants/text";
import useScreenSize from "hooks/use-screen-size";
import Media from "components/Media";
import OddLine from "../../../../../public/common/line-1.svg";
import EvenLine from "../../../../../public/common/line-2.svg";

import styles from "./styles.module.scss";

const HowItWorks: FC = () => {
  const { title, subtitle, steps } = text.homepage.how_it_works;
  const { isResponsive, screenWidth } = useScreenSize();

  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <Label className={styles.title} variant="h-centered" color="white">
        {title}
      </Label>
      <Label className={styles.subtitle} variant="s-centered" color="white">
        {subtitle}
      </Label>
      <Media className={styles.desktopSteps} at={["desktop", "tablet"]}>
        <div className={styles.steps}>
          {steps.map(({ image }, index) => (
            <div className={styles.step} key={image}>
              <Image
                alt=""
                className={styles.image}
                src={image}
                layout="fixed"
                width={isResponsive ? 190 : 250}
                height={isResponsive ? 240 : 320}
              />
              {index !== steps.length - 1 &&
                (index % 2 ? (
                  <OddLine className={styles.line} />
                ) : (
                  <EvenLine className={styles.line} />
                ))}
            </div>
          ))}
        </div>
      </Media>
      <Media className={styles.mobileSteps} at={["mobile"]}>
        <Swiper loop slidesPerView={screenWidth / (250 + 30)} centeredSlides>
          {steps.map(({ image }) => (
            <SwiperSlide key={image}>
              <div className={styles.step}>
                <Image
                  alt=""
                  className={styles.image}
                  src={image}
                  layout="fixed"
                  width={250}
                  height={320}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Media>
    </Content>
  );
};

export default HowItWorks;
