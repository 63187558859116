import Content from "components/Content";
import Label from "components/Label";
import React, { FC } from "react";
import text from "constants/text";

import AnimatedGrowth from "./components/AnimatedGrowth";
import styles from "./styles.module.scss";

const WeAreGrowing: FC = () => {
  const { metrics, title } = text.homepage.we_are_growing;

  return (
    <Content
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <Label className={styles.title} variant="h-centered" color="primary">
        {title}
      </Label>
      <div className={styles.metrics}>
        {metrics.map(({ value, title: mTitle, withPostfix, animated }) => (
          <AnimatedGrowth
            titleClassName={styles.metricValue}
            subtitleClassName={styles.metricLabel}
            color="primary"
            animated={animated}
            withPostfix={withPostfix}
            key={mTitle}
            title={mTitle}
            value={value}
          />
        ))}
      </div>
    </Content>
  );
};

export default WeAreGrowing;
