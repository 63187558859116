import Button from "components/Button";
import { AnimatePresence, motion } from "framer-motion";
import React, { FC, ReactChild, useMemo, useState } from "react";
import { v4 as uuid } from "uuid";
import {
  HiChevronRight as ChevronRight,
  HiChevronLeft as ChevronLeft
} from "react-icons/hi";

import styles from "./styles.module.scss";

interface Props {
  items: ReactChild[];
  width: number;
  height: number;
  onChange?(i: number): void;
}

type WithChild =
  | { children: ReactChild[]; childHeight: number }
  | { children?: never; childHeight?: number };

const ArrowCarrousel: FC<Props & WithChild> = ({
  items,
  width,
  height,
  childHeight,
  onChange = () => {},
  children = []
}) => {
  const uniqueID = useMemo(uuid, []);

  const [animating, setAnimating] = useState(false);
  const [index, setIndex] = useState(0);
  const [forward, setForward] = useState(true);

  const nextItem = () => {
    if (index === items.length - 1) {
      onChange(0);
      setIndex(0);
    } else {
      onChange(index + 1);
      setIndex(index + 1);
    }

    setForward(true);
  };

  const prevItem = () => {
    if (index === 0) {
      setIndex(items.length - 1);
      onChange(items.length - 1);
    } else {
      setIndex(index - 1);
      onChange(index - 1);
    }

    setForward(false);
  };

  const variants = {
    initial: ({ forward: cForward }: { forward: boolean }) => ({
      ...(cForward ? { left: "100%" } : { left: "-100%" }),
      width: 0,
      zIndex: 1
    }),
    exit: ({ forward: cForward }: { forward: boolean }) => ({
      ...(cForward ? { left: "-100%" } : { left: "100%" }),
      width: 0,
      zIndex: 0
    }),
    active: ({ forward: cForward }: { forward: boolean }) => ({
      ...(cForward ? { left: 0 } : { left: 0 }),
      width: "100%",
      zIndex: 1
    })
  };

  return (
    <div>
      <div className={styles.container}>
        <Button
          className={styles.button}
          disabled={animating}
          variant="text"
          onClick={prevItem}
          Icon={<ChevronLeft className={styles.chevronLeft} />}
        />
        <div style={{ width, height }} className={styles.carousel}>
          <AnimatePresence>
            <motion.div
              transition={{ duration: 0.7, type: "spring", bounce: 0.35 }}
              onAnimationStart={() => setAnimating(true)}
              onAnimationComplete={() => setAnimating(false)}
              className={styles.item}
              animate="active"
              exit="exit"
              initial="initial"
              custom={{ forward }}
              variants={variants}
              key={`${uniqueID}-${index}`}
            >
              {items[index]}
            </motion.div>
          </AnimatePresence>
        </div>
        <Button
          className={styles.button}
          disabled={animating}
          variant="text"
          onClick={nextItem}
          Icon={<ChevronRight className={styles.chevronRight} />}
        />
      </div>
      <div
        style={{ height: childHeight, width }}
        className={styles.childContainer}
      >
        <AnimatePresence>
          <motion.div
            className={styles.child}
            transition={{ duration: 0.7, type: "spring", bounce: 0.35 }}
            animate="active"
            exit="exit"
            initial="initial"
            custom={{ forward }}
            variants={variants}
            key={`${uniqueID}-${index}-child`}
          >
            {children[index]}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

ArrowCarrousel.defaultProps = {
  onChange: () => {},
  children: []
};

export default ArrowCarrousel;
