import Label from "components/Label";
import useAnimatedValue from "hooks/use-animated-value";
import React, { FC, useEffect, useState } from "react";
import { motion } from "framer-motion";

import styles from "./styles.module.scss";

type Props = {
  value: number;
  withPostfix?: boolean;
  title: string;
  animated?: boolean;
  titleClassName?: string;
  subtitleClassName?: string;
  color?: string;
};

const AnimatedGrowth: FC<Props> = ({
  value,
  withPostfix,
  title,
  animated,
  titleClassName,
  subtitleClassName,
  color = "white"
}) => {
  const [triggerAnimation, setTriggerAnimation] = useState(false);

  const { animate, animatedValue, animationFinished } = useAnimatedValue({
    transition: "easeOut"
  });

  useEffect(() => {
    if (triggerAnimation) animate(0, value, 2500);
  }, [triggerAnimation]);

  return (
    <motion.div
      onViewportEnter={() => setTriggerAnimation(true)}
      className={styles.metric}
    >
      <div className={styles.value}>
        <Label className={titleClassName} variant="h" color={color}>
          {animated ? Math.round(animatedValue) : value}
        </Label>
        {withPostfix && animationFinished && (
          <Label
            variant="h"
            color={color}
            className={`${styles.postfix} ${titleClassName}`}
          >
            +
          </Label>
        )}
      </div>
      <div>
        <Label
          useMarkdown
          className={subtitleClassName}
          variant="centered-body"
          color={color}
        >
          {title}
        </Label>
      </div>
    </motion.div>
  );
};

AnimatedGrowth.defaultProps = {
  withPostfix: false,
  animated: false,
  titleClassName: undefined,
  subtitleClassName: undefined,
  color: "white"
};

export default AnimatedGrowth;
