import React, { FC } from "react";
import Label from "components/Label";
import Button from "components/Button";
import Content from "components/Content";
import { useRouter } from "next/router";

import Image from "next/image";
import Background from "../../../../../public/homepage/main_headline/bg.png";
import MobileBackground from "../../../../../public/homepage/main_headline/mbg.png";

import styles from "./styles.module.scss";

const MainHeadline: FC = () => {
  const router = useRouter();

  return (
    <Content containerClassName={styles.wrapper}>
      <div className={styles.container}>
        <Label variant="h-centered" color="white" className={styles.title}>
          Your Home, on Autopilot
        </Label>
        <Label variant="s-centered" color="white" className={styles.subtitle}>
          Humming Homes provides a digital home manager that handles any home
          service for you - from plumbing repairs to replacing your HVAC system
          and much more. Starting at $199 / year
        </Label>
        <Button
          className={styles.cta}
          variant="cta"
          // track={{ event: "LearnMoreClicked", location: "MainHeadline" }}
        >
          Get Started
        </Button>
      </div>
      <Image
        alt=""
        className={styles.background}
        src={Background}
        layout="fill"
        objectFit="cover"
      />
      <Image
        alt=""
        className={styles.mobileBackground}
        src={MobileBackground}
        layout="fill"
        objectFit="cover"
      />
    </Content>
  );
};

export default MainHeadline;
