import React, { ElementType, FC } from "react";

import styles from "./styles.module.scss";

type Props = {
  Logo: ElementType;
};

const Feature: FC<Props> = ({ Logo }) => {
  return (
    <div className={styles.container}>
      <Logo className={styles.logo} />
    </div>
  );
};

export default Feature;
