import MainHeadline from "./MainHeadline";
import StartHumming from "../../components/StartHumming";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import WhereWeAre from "./WhereWeAre";
import WeAreGrowing from "./WeAreGrowing";
import ToDoList from "./ToDoList";
import OneMembership from "./OneMembership";
import OurServices from "./OurServices";
import UserReviews from "./UserReviews";
import YourMembership from "./YourMembership";
import Footer from "./Footer";

export {
  ToDoList,
  MainHeadline,
  StartHumming,
  Features,
  HowItWorks,
  WhereWeAre,
  WeAreGrowing,
  OneMembership,
  OurServices,
  UserReviews,
  YourMembership,
  Footer
};
