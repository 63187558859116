import { ElementType } from "react";
import Clipboard from "../../../../../public/common/base-icons/clipboard.svg";
import Emergency from "../../../../../public/common/base-icons/emergency.svg";
import Drill from "../../../../../public/common/base-icons/drill.svg";
import Hammer from "../../../../../public/common/base-icons/hammer.svg";
import Screwdriver from "../../../../../public/common/base-icons/screwdriver.svg";
import Wrench from "../../../../../public/common/base-icons/wrench.svg";
import Faucet from "../../../../../public/common/base-icons/water-faucet.svg";
import Home from "../../../../../public/common/base-icons/home.svg";
import PoolMaintenance from "../../../../../public/common/base-icons/pool-maintenance.svg";
import HVAC from "../../../../../public/common/base-icons/hvac.svg";
import Leaves from "../../../../../public/common/base-icons/leaves.svg";
import Electrical from "../../../../../public/common/base-icons/electrical.svg";
import PaintRoller from "../../../../../public/common/base-icons/paintroller.svg";
import Plumbing from "../../../../../public/common/base-icons/plumbing.svg";

export const icons: { [key: string]: ElementType } = {
  clipboard: Clipboard,
  emergency: Emergency,
  drill: Drill,
  hammer: Hammer,
  screwdriver: Screwdriver,
  wrench: Wrench,
  "water-faucet": Faucet,
  home: Home,
  "pool-maintenance": PoolMaintenance,
  hvac: HVAC,
  leaves: Leaves,
  electrical: Electrical,
  paintroller: PaintRoller,
  plumbing: Plumbing
};
