import React from "react";
import BusinessInsider from "../../../../../public/homepage/features/business-insider.svg";
import Yahoo from "../../../../../public/homepage/features/yahoo.svg";
import LocalMoms from "../../../../../public/homepage/features/local-moms.png";
import InMan from "../../../../../public/homepage/features/in-man.svg";
import RealDeal from "../../../../../public/homepage/features/real-deal.svg";

export const features = [
  {
    key: "real-deal",
    Logo: RealDeal,
    review: ""
  },
  {
    key: "yahoo",
    Logo: Yahoo,
    review: `"Humming Homes is concentrating homeowner demand and push localized marketplaces that provide better availability, better pricing and a better consumer experience - that's what homeowners need."`
  },
  {
    key: "b-insider",
    Logo: BusinessInsider,
    review: `“Humming Homes, lets homeowners request maintenance help as if they were renters."`
  },
  {
    key: "im",
    Logo: InMan,
    review: `“Douglas Elliman parent company invests in another proptech startup."`
  },
  {
    key: "local-moms",
    Logo: ({ className }: { className: string }) => (
      <img className={className} src={LocalMoms.src} alt="" />
    ),
    review: `“Game changer for families everywhere ! When Humming Homes tells us a vendor is coming, we know they are actually coming.”`
  }
];
