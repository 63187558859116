import Content from "components/Content";
import Label from "components/Label";
import React, { FC } from "react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

import text from "constants/text";

import styles from "./styles.module.scss";
import YourMembership from "../YourMembership";

const OneMembership: FC = () => {
  const { title, subtitle } = text.homepage.one_membership;

  return (
    <>
      <Content
        containerClassName={styles.wrapper}
        contentClassName={styles.container}
      >
        <div>
          <div className={styles.intro}>
            <Label
              useMarkdown
              className={styles.title}
              variant="h"
              color="white"
            >
              {title}
            </Label>
            <Label className={styles.subtitle} variant="s" color="white">
              {subtitle}
            </Label>
          </div>
        </div>
      </Content>
      <YourMembership />
    </>
  );
};

export default OneMembership;
