import Content from "components/Content";
import React, { FC } from "react";

import text from "constants/text";

import Label from "components/Label";
import Image from "next/image";
import useScreenSize from "hooks/use-screen-size";
import { icons } from "./constants";

import styles from "./styles.module.scss";
import ArrowCarrousel from "./components/ArrowCarrousel";

const ToDoList: FC = () => {
  const { isMobile, isResponsive, screenWidth } = useScreenSize();

  const { title, subtitle, reviews } = text.homepage.to_do_list;

  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <Label className={styles.title} color="lightPrimary" variant="h-centered">
        {title}
      </Label>
      <Label
        className={styles.subtitle}
        color="lightPrimary"
        variant="s-centered"
      >
        {subtitle}
      </Label>
      <ArrowCarrousel
        childHeight={300}
        width={
          isResponsive
            ? isMobile
              ? Math.min(screenWidth - 40, 350)
              : 300
            : 480
        }
        height={isResponsive ? 100 : 130}
        items={reviews.map(({ looking_for_help_with, pp }) => (
          <div className={styles.carrouselItemWrapper}>
            <div key={pp} className={styles.carrouselItem}>
              <Image
                alt=""
                layout="fixed"
                width={isResponsive ? 45 : 84}
                height={isResponsive ? 45 : 84}
                src={pp}
              />
              <div className={styles.rightPanel}>
                <Label
                  className={styles.helpTitle}
                  color="lightPrimary"
                  variant="s-capitalized"
                >
                  I&apos;M LOOKING FOR HELP WITH...
                </Label>
                <Label
                  className={styles.lookingFor}
                  color="lightPrimary"
                  variant="h"
                >
                  {looking_for_help_with}
                </Label>
              </div>
            </div>
          </div>
        ))}
      >
        {reviews.map(({ comment, services }) => (
          <div className={styles.review}>
            <Label
              className={styles.comment}
              variant="s-centered"
              color="lightPrimary"
            >
              {`“${comment}”`}
            </Label>
            <div className={styles.services}>
              {services.map(({ name, icon }) => {
                const Icon = icons[icon];

                return (
                  <div key={`${name}-${icon}`} className={styles.service}>
                    <Icon className={styles.serviceIcon} />
                    <Label
                      className={styles.serviceLabel}
                      variant="s-centered"
                      color="lightPrimary"
                    >
                      {name}
                    </Label>
                  </div>
                );
              })}
              <div className={styles.divider} />
            </div>
          </div>
        ))}
      </ArrowCarrousel>
    </Content>
  );
};

export default ToDoList;
