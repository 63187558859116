import Content from "components/Content";
import Label from "components/Label";
import Media from "components/Media";
import text from "constants/text";
import useComponentDimensions from "hooks/use-component-dimensions";
import Image from "next/image";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import React, { FC, useState } from "react";
import { HiChevronDown as Chevron } from "react-icons/hi";

import useScreenSize from "hooks/use-screen-size";
import Collapsable from "components/Collapsable";
import Button from "components/Button";
import styles from "./styles.module.scss";

const WhereWeAre: FC = () => {
  const { title, locations } = text.homepage.where_we_are;
  const { measure, width } = useComponentDimensions();
  const { isResponsive, isMobile } = useScreenSize();

  const [openCities, setOpenCities] = useState(false);

  return (
    <Content containerClassName={styles.wrapper}>
      <div ref={measure} className={styles.container}>
        <Label className={styles.title} variant="h-centered" color="primary">
          {title}
        </Label>
        <Media at={["tablet", "desktop"]}>
          <div className={styles.locations}>
            {locations.map(({ title: locationTitle, image, cities }) => (
              <div className={styles.location}>
                <button
                  className={styles.imageCard}
                  onClick={() => setOpenCities(true)}
                  type="button"
                >
                  <Image
                    alt=""
                    height={270}
                    width={
                      (width - (locations.length + 1) * 25) / locations.length
                    }
                    src={image}
                  />

                  <div className={styles.cardText}>
                    <Label className={styles.cardTitle} variant="h6-centered">
                      {locationTitle}
                    </Label>
                  </div>
                </button>
                <Collapsable
                  disabled
                  open={openCities}
                  header={<div />}
                  className={styles.cHeader}
                  withIcon={false}
                >
                  {cities.map((city) => (
                    <Label
                      className={styles.city}
                      variant="s-centered"
                      color="primary"
                    >
                      {city}
                    </Label>
                  ))}
                </Collapsable>
              </div>
            ))}
          </div>
        </Media>
        <Media className={styles.carrouselWrapper} at={["mobile"]}>
          {!!width && (
            <Swiper
              initialSlide={Math.floor(locations.length / 2)}
              slidesPerView={
                width / (160 + (isResponsive ? (isMobile ? 10 : 25) : 25))
              }
              centeredSlides
            >
              {locations.map(({ image, cities }) => (
                <SwiperSlide key={image} className={styles.slide}>
                  <div className={styles.location}>
                    <Image
                      alt=""
                      className={styles.mobileImage}
                      height={200}
                      width={160}
                      src={image}
                    />
                    <Collapsable
                      className={styles.cHeader}
                      disabled
                      open={openCities}
                      header={<div />}
                      withIcon={false}
                    >
                      {cities.map((city) => (
                        <Label
                          className={styles.city}
                          variant="s-centered"
                          color="primary"
                        >
                          {city}
                        </Label>
                      ))}
                    </Collapsable>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </Media>
        <Button
          iconAfter
          Icon={
            <Chevron
              className={`${styles.chevron} ${
                openCities && styles.rotatedChevron
              }`}
            />
          }
          variant="text"
          onClick={() => setOpenCities(!openCities)}
        >
          {openCities ? "Hide cities" : "Show Cities"}
        </Button>
      </div>
    </Content>
  );
};

export default WhereWeAre;
