import Button from "components/Button";
import Content from "components/Content";
import Label from "components/Label";
import Media from "components/Media";
import text from "constants/text";
import useComponentDimensions from "hooks/use-component-dimensions";
import useScreenSize from "hooks/use-screen-size";
import React, { FC, useState } from "react";
import {
  HiChevronRight as ChevronRight,
  HiChevronLeft as ChevronLeft
} from "react-icons/hi";

import Star from "../../../../../public/common/star.svg";

import styles from "./styles.module.scss";

const UserReviews: FC = () => {
  const [mobileIndex, setMobileIndex] = useState(0);

  const { isMobile, isTablet } = useScreenSize();

  const { measure, width: commentWidth } = useComponentDimensions();

  const { reviews } = text.homepage.user_reviews;

  const defaulReviewer = reviews[0].reviewer;
  const [selectedReview, setSelectedReview] = useState(defaulReviewer);

  const foundIndex = reviews.findIndex(
    ({ reviewer }) => reviewer === selectedReview
  );

  const index = isMobile ? mobileIndex : foundIndex === -1 ? 0 : foundIndex;

  const mobileReview = reviews[mobileIndex];

  const x = -index * commentWidth;

  return (
    <Content
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <Label
        className={styles.googleLabel}
        variant="s-centered-capitalized"
        color="primary"
      >
        Ratings &amp; Reviews
      </Label>
      <div className={styles.stars}>
        {new Array(5).fill(0).map(() => (
          <Star className={styles.star} />
        ))}
      </div>
      <div ref={measure} className={styles.commentsContainer}>
        <Media className={styles.leftButton} at={["mobile"]}>
          <Button
            onClick={() => {
              if (mobileIndex === 0) setMobileIndex(reviews.length - 1);
              else setMobileIndex(mobileIndex - 1);
            }}
            variant="text"
            Icon={<ChevronLeft className={styles.chevron} />}
          />
        </Media>
        <Media className={styles.rightButton} at={["mobile"]}>
          <Button
            onClick={() => {
              if (mobileIndex === reviews.length - 1) setMobileIndex(0);
              else setMobileIndex(mobileIndex + 1);
            }}
            variant="text"
            Icon={<ChevronRight className={styles.chevron} />}
          />
        </Media>
        <div
          style={{ transform: `translateX(${x}px)` }}
          className={styles.comments}
        >
          {reviews.map(({ content }, i) => (
            <div
              className={`${styles.comment} ${
                i === index && styles.selectedComment
              }`}
            >
              <Label color="primary" variant="s-centered">
                {content}
              </Label>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.reviewers}>
        {reviews.map(({ location, reviewer }) => (
          <div
            className={`${styles.reviewerInfo} ${
              selectedReview === reviewer && styles.selectedReviewer
            }`}
            {...(isTablet
              ? { onClick: () => setSelectedReview(reviewer) }
              : { onMouseEnter: () => setSelectedReview(reviewer) })}
          >
            <Label
              className={styles.reviewer}
              color="primary"
              variant="s-centered-capitalized-bold"
            >
              {reviewer}
            </Label>
            <Label
              className={styles.location}
              color="primary"
              variant="s-centered-capitalized-bold"
            >
              {location}
            </Label>
          </div>
        ))}
      </div>
      <div className={styles.mobileReviewers}>
        <div
          className={`${styles.reviewerInfo} ${styles.selectedReviewer}`}
          {...(isTablet
            ? { onClick: () => setSelectedReview(mobileReview.reviewer) }
            : { onMouseEnter: () => setSelectedReview(mobileReview.reviewer) })}
        >
          <Label
            className={styles.reviewer}
            color="primary"
            variant="s-centered-capitalized-bold"
          >
            {mobileReview.reviewer}
          </Label>
          <Label
            className={styles.location}
            color="primary"
            variant="s-centered-capitalized-bold"
          >
            {mobileReview.location}
          </Label>
        </div>
      </div>
    </Content>
  );
};

export default UserReviews;
