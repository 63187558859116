import Button from "components/Button";
import Content from "components/Content";
import Label from "components/Label";
import useScreenSize from "hooks/use-screen-size";
import Image from "next/image";
import React, { FC } from "react";
import { useRouter } from "next/router";

import styles from "./styles.module.scss";

const Heading: FC = () => {
  const { isResponsive, isMobile } = useScreenSize();

  const router = useRouter();

  return (
    <Content
      contentClassName={styles.container}
      containerClassName={styles.wrapper}
    >
      <div className={styles.top}>
        <div className={styles.leftPanel}>
          <div className={styles.titleWrapper}>
            <Label
              className={styles.title}
              useMarkdown
              variant="h"
              color="lightPrimary"
            >
              Ready to Get Started?
            </Label>
          </div>
          <div className={styles.subtitleWrapper}>
            <Label
              className={styles.subtitle}
              useMarkdown
              variant="s"
              color="lightPrimary"
            >
              Don&apos;t let your home be a burden - get your personalized
              digital home manager to work for you so you can get back to
              enjoying time on the things you love.
            </Label>
          </div>
          {/* <Button
            onClick={() => {
              router.push("/start");
            }}
            variant="cta"
          >
            Join Now
          </Button> */}
        </div>
        <div className={styles.rightPanel}>
          <Image
            alt=""
            src="/our-membership/heading/Header-Mandala.png"
            layout="fixed"
            width={isResponsive ? (isMobile ? 250 : 300) : 450}
            height={isResponsive ? (isMobile ? 250 : 300) : 450}
          />
        </div>
      </div>
    </Content>
  );
};

export default Heading;
