import { useRef, useState } from "react";

import { TRANSITION_FUNCTIONS } from "./constants";

type Config = {
  transition: "linear" | "easeInOut" | "easeIn" | "easeOut";
};

const useAnimatedValue = (config: Config = { transition: "easeIn" }) => {
  const startRef = useRef<Date | null>(null);
  const requestRef = useRef<number | null>(null);
  const [animatedValue, setAnimatedValue] = useState(0);

  const { transition } = config;

  const animate = (from: number, to: number, duration: number) => {
    startRef.current = new Date();

    const reAnimate = () => {
      const elapsed = Math.min(
        startRef.current ? Date.now() - startRef.current.getTime() : 0,
        duration
      );

      if (to < from)
        setAnimatedValue(
          (1 - TRANSITION_FUNCTIONS[transition](elapsed / duration)) *
            (from - to) +
            to
        );
      else
        setAnimatedValue(
          TRANSITION_FUNCTIONS[transition](elapsed / duration) * (to - from) +
            from
        );

      if (elapsed >= duration) return;

      requestRef.current = requestAnimationFrame(reAnimate);
    };

    requestRef.current = requestAnimationFrame(reAnimate);
  };

  return {
    animate,
    animatedValue,
    animationFinished: !!startRef.current
  };
};

export default useAnimatedValue;
