import { ElementType } from "react";
import Clipboard from "../../../../../public/common/outlined-icons/clipboard.svg";
import Hammer from "../../../../../public/common/outlined-icons/hammer.svg";
import Home from "../../../../../public/common/outlined-icons/home.svg";
import Lawnmower from "../../../../../public/common/outlined-icons/lawnmower.svg";
import Leaves from "../../../../../public/common/outlined-icons/leaves.svg";
import Electrical from "../../../../../public/common/outlined-icons/electrical.svg";
import PoolMaintenance from "../../../../../public/common/outlined-icons/pool-maintenance.svg";
import Paintroller from "../../../../../public/common/outlined-icons/paintroller.svg";
import Plumbing from "../../../../../public/common/outlined-icons/plumbing.svg";
import Wrench from "../../../../../public/common/outlined-icons/wrench.svg";
import Drill from "../../../../../public/common/outlined-icons/drill.svg";
import WaterFaucet from "../../../../../public/common/outlined-icons/water-faucet.svg";
import Hvac from "../../../../../public/common/outlined-icons/hvac.svg";

export const icons: { [key: string]: ElementType } = {
  clipboard: Clipboard,
  hammer: Hammer,
  home: Home,
  lawnmower: Lawnmower,
  leaves: Leaves,
  electrical: Electrical,
  "pool-maintenance": PoolMaintenance,
  paintroller: Paintroller,
  plumbing: Plumbing,
  wrench: Wrench,
  drill: Drill,
  "water-faucet": WaterFaucet,
  hvac: Hvac
};
