import React, { FC } from "react";

import Content from "components/Content";
import Label from "components/Label";
import text from "constants/text";

import Button from "components/Button";
import { useRouter } from "next/router";
import styles from "./styles.module.scss";
import { icons } from "./constants";
import Image from "next/image";

const OurServices: FC = () => {
  const { title, subtitle, network } = text.homepage.our_services;

  const router = useRouter();

  return (
    <Content
      containerClassName={styles.wrapper}
      contentClassName={styles.container}
    >
      <Label color="primary" variant="h" className={styles.title}>
        {title}
      </Label>
      <Label className={styles.subtitle} variant="s" color="primary">
        {subtitle}
      </Label>
      <div className={styles.content}>
        <div className={styles.imageContainer}>
          <Image
            alt=""
            src="/chat-flow-desktop.png"
            width="2488"
            height="2280"
            className={styles.chatFlowImage}
          />
        </div>
      </div>
      <div className={styles.mobileContent}>
        <div className={styles.imageContainer}>
          <Image
            alt=""
            src="/chat-flow-mobile-upscaled.png"
            width="750"
            height="718"
            className={styles.chatFlowImage}
          />
        </div>
      </div>
      <div className={styles.network}>
        <Label
          className={styles.networkTitle}
          variant="uppercase"
          color="primary"
        >
          {network.title}
        </Label>
        <div className={styles.offerings}>
          {network.offerings.map(({ name, icon }) => {
            const Icon = icons[icon];

            return (
              <div className={styles.networkOffering}>
                <Icon className={styles.networkIcon} />
                <Label variant="s-centered" color="supplemental">
                  {name}
                </Label>
              </div>
            );
          })}
        </div>
        {/* <Button
          className={styles.cta}
          variant="cta"
          onClick={() => router.push("/services")}
        >
          {network.cta}
        </Button> */}
      </div>
    </Content>
  );
};

export default OurServices;
